@import "~tachyons/css/tachyons.min.css";
@import "~stream-chat-react/dist/css/index.css";

body {
  margin: 0;
  font-family: "Avenir Next", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 10px;
}

.messaging.str-chat .str-chat__header-livestream {
  z-index: auto;
}

.react-pdf__Page__canvas {
  max-width: 100%;
  height: auto !important;
}

.chat-wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
}

.chat-wrapper .foo {
  height: 50vh;
}

.chat-wrapper .str-chat {
  height: 100%;
  width: 300px;
}

.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
}
